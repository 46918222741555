<template>
  <v-data-table
    :headers="headers"
    :items="member_product_points"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
<!--        <v-dialog-->
<!--          v-model="dialog"-->
<!--          max-width="500px"-->
<!--        >-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            &lt;!&ndash; <div class="d-flex justify-space-between"> &ndash;&gt;-->
<!--            &lt;!&ndash; <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> &ndash;&gt;-->
<!--            <v-btn-->
<!--              color="primary"-->
<!--              dark-->
<!--              class="mb-2"-->
<!--              v-bind="attrs"-->
<!--              v-on="on"-->
<!--            >-->
<!--              เพิ่มคะแนน-->
<!--            </v-btn>-->
<!--            &lt;!&ndash; </div> &ndash;&gt;-->
<!--          </template>-->
<!--          <v-card>-->
<!--            <v-card-title>-->
<!--              <span class="text-h5">{{ formTitle }}</span>-->
<!--            </v-card-title>-->

<!--            <v-card-text>-->
<!--              <v-container>-->
<!--                <v-row>-->
<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12"-->
<!--                  >-->
<!--                    <v-text-field-->
<!--                      v-model="editedItem.image_path"-->
<!--                      label="รูป"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12"-->
<!--                  >-->
<!--                    <v-text-field-->
<!--                      v-model="editedItem.productPoint_name"-->
<!--                      label="ชื่อสินค้า"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12">-->
<!--                    <v-text-field-->
<!--                      v-model="editedItem.amount"-->
<!--                      label="จำนวนสินค้า"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->

<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12">-->
<!--                    <v-text-field-->
<!--                      v-model="editedItem.score"-->
<!--                      label="คะแนน"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->

<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12">-->
<!--                    <v-text-field-->
<!--                      v-model="editedItem.detail"-->
<!--                      label="คำอธิบาย"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-container>-->
<!--            </v-card-text>-->

<!--            <v-card-actions>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn-->
<!--                color="error"-->
<!--                text-->
<!--                @click="close"-->
<!--              >-->
<!--                Cancel-->
<!--              </v-btn>-->
<!--              <v-btn-->
<!--                color="primary"-->
<!--                text-->
<!--                @click="save"-->
<!--              >-->
<!--                Save-->
<!--              </v-btn>-->
<!--            </v-card-actions>-->
<!--          </v-card>-->
<!--        </v-dialog>-->
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ member_product_points.indexOf(item) + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_member_product_point from '../../../services/memberProductPoint'
import Swal from "sweetalert2";

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    member_product_points: [],
    dialog: false,
    dialogDelete: false,
    serverOptions : {
      page: 1,
      itemsPerPage: 10,

    },
    total : 0,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      { text: 'รูป/ภาพ', value: 'image_path' , sortable: false},
      { text: 'ชื่อสินค้า', value: 'productPoint_name' },
      { text: 'ชื่อลูกค้า', value: 'productPoint_name' },
      { text: 'คะแนนที่แลก', value: 'productPoint_name' },
      { text: 'ชื่อพนักงานที่อนุมัติ', value: 'amount' },
      { text: 'เวลา', value: 'stock' },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      productPoint_id : '',
      image_path : '',
      productPoint_name : '',
      detail : '',
      stock : 0,
      amount : 0,
      score : 0,
    },
    defaultItem: {
      productPoint_id : '',
      image_path : '',
      productPoint_name : '',
      detail : '',
      stock : 0,
      amount : 0,
      score : 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม คะแนน' : 'แก้ไข คะแนน'
    },
  },

  watch: {
    serverOptions : {
      handler(){
        this.getProductPoint()
      },
    },
    search : {
      handler(){
        this.getProductPoint()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getProductPoint()
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.member_product_points.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.product_points.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      instance_member_product_point.memberProductPointDestroy(this.editedItem).then(res => {

        this.member_product_points.splice(this.editedIndex, 1)
      }).catch(err => {

      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        instance_member_product_point.memberProductPointUpdate(this.editedItem)
          .then(res => {
          Object.assign(this.member_product_points[this.editedIndex], this.editedItem)
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })

        });
      } else {
        instance_member_product_point.memberProductPointStore(this.editedItem)
          .then(res => {
          this.member_product_points.push(this.editedItem)
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })

        });
      }
      this.close()
    },
    getProductPoint() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        page : this.serverOptions.page,
        size : this.serverOptions.itemsPerPage,
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? '',
      }

      instance_member_product_point.getMemberProductPoint(data).then(res => {
        this.product_points = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
